import {Button, Card, Col, Divider, Row, Space, Typography} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {
  useCreateNegotiationCoverageConfirmationMutation,
  useGetNegotiationCoverageConfirmationQuery,
  useGetNegotiationDetailQuery
} from "../../redux/api/negotiationsApiSlice";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {LeftOutlined, Loading3QuartersOutlined} from "@ant-design/icons";
import NegotiationDetailList from "../../components/negotiationForm/sidebars/status/NegotiationDetailList";
import NegotiationStatus from "../../components/negotiationForm/sidebars/status/NegotiationStatus";
import CoverageConfirmationSurvey from "./CoverageConfirmationSurvey";
import {SurveyContext} from "../../context/SurveyContext";
import CoverageConfirmationDocumentUploadModalButton from "./CoverageConfirmationDocumentUploadModalButton";
import CoverageConfirmationStatusTag from "./CoverageConfirmationStatusTag";
import SelectSurveyPageCard from "./SelectSurveyPageCard";

const {Title} = Typography;

const CoverageConfirmationPage = () => {

  const {id} = useParams();
  const navigate = useNavigate();

  const isCreationPending = useRef(false);

  const [polling, setPolling] = useState<boolean>(true);

  const {
    data: negotiation,
    isLoading: isNegotiationsDetailsLoading,
    isFetching: isNegotiationsDetailsFetching,
    refetch: refetchNegotiation,
  } = useGetNegotiationDetailQuery(id ?? skipToken)


  const {
    data: coverageConfirmation,
    refetch: refetchCoverageConfirmation,
    isLoading: isCoverageConfirmationLoading
  } = useGetNegotiationCoverageConfirmationQuery(negotiation?.coverageconfirmation ?? skipToken);

  const [
    createCoverageConfirmation,
    {
      isLoading: isCreatingCoverageConfirmation,
    }
  ] = useCreateNegotiationCoverageConfirmationMutation();

  useEffect(() => {
    if (negotiation?.coverageconfirmation === null && !isCreationPending.current) {
      isCreationPending.current = true;
      createCoverageConfirmation(negotiation.uuid)
        .unwrap()
        .then(() => {
          refetchNegotiation();
          isCreationPending.current = false;
        });
    }
  }, [coverageConfirmation, createCoverageConfirmation, negotiation, refetchNegotiation]);

  useEffect(() => {
    const pollingHandler = () => {
      if (coverageConfirmation?.state !== '0000') {
        setPolling(true)
        refetchCoverageConfirmation();
      }
    }
    const intervalId = setInterval(pollingHandler, 7000);
    pollingHandler();
    return () => clearInterval(intervalId);
  }, [coverageConfirmation, refetchCoverageConfirmation, polling]);

  useEffect(() => {
    if (coverageConfirmation?.state === '0000') {
      setPolling(false);
    }
  }, [coverageConfirmation]);

  /**
   * Stile per l'overlay visibile.
   *
   * @type {React.CSSProperties}
   */
  const styleVisible: React.CSSProperties = useMemo(
    () => ({
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(255,255,255,0.5)",
      zIndex: 10000,
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: "opacity 0.5s ease, visibility 0.5s ease",
      opacity: 1,
      visibility: "visible",
    }),
    [],
  );

  /**
   * Stile per l'overlay invisibile.
   *
   * @type {React.CSSProperties}
   */
  const styleInvisible: React.CSSProperties = useMemo(
    () => ({
      opacity: 0,
      visibility: "hidden",
    }),
    [],
  );


  return (
    <SurveyContext>
      <main style={{padding: "1rem 0.5rem"}}>
        <Row gutter={[8, 8]}>
          <Col
            xs={{span: 24, order: 1}}
            xl={{span: 4, order: 1}}>
            <Button
              type={"link"}
              icon={<LeftOutlined/>}
              style={{paddingLeft: 0}}
              onClick={() => navigate(`/negotiations/${id}`)}>
              Torna alla trattativa
            </Button>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col
            xs={{span: 24, order: 3}}
            sm={{span: 24}}
            md={{span: 24}}
            lg={{span: 15, order: 2}}
            xl={{span: 16, order: 3}}>
            <div
              style={{...styleVisible, ...(!polling ? styleInvisible : {})}}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}>
              <Space direction={"vertical"} align={"center"} >
                <Loading3QuartersOutlined
                  spin={true}
                  style={{fontSize: 20, color: "#1677ff"}}
                />
                Lettura dei documenti in corso...
              </Space>

            </div>
            <Card
              loading={isNegotiationsDetailsLoading || isNegotiationsDetailsFetching || isCoverageConfirmationLoading || isCreatingCoverageConfirmation}>
              <Row style={{marginBottom: 24}} justify={"space-between"}>
                <Col>
                  <Space size={8}>
                    <Title level={4}>
                      Conferma di copertura
                    </Title>
                    {coverageConfirmation &&
                      <CoverageConfirmationStatusTag coverageConfirmation={coverageConfirmation}/>}
                  </Space>

                </Col>
                <Col>
                  <CoverageConfirmationDocumentUploadModalButton/>
                </Col>
              </Row>
              <CoverageConfirmationSurvey/>
            </Card>
          </Col>
          <Col
            xs={{span: 24, order: 2}}
            sm={{span: 24}}
            md={{span: 24}}
            lg={{span: 9, order: 3}}
            xl={{span: 8, order: 3}}>
            <Space direction={"vertical"} size={16} style={{width: '100%'}}>
              <SelectSurveyPageCard disabled={polling}/>
              {negotiation && (
                <Card>
                  <NegotiationStatus negotiation={negotiation}/>
                  <Divider style={{margin: '1rem 0'}}/>
                  <NegotiationDetailList
                    negotiation={negotiation}
                    isLoading={isNegotiationsDetailsFetching || isNegotiationsDetailsLoading}/>
                  <Row justify={"center"} style={{marginTop: '1rem'}}>
                    <Col span={24}>
                      <Button type={"primary"} style={{width: '100%'}}
                              disabled={polling || (negotiation.state !== 6 && negotiation.state !== 8)}>
                        Conferma invio
                      </Button>
                    </Col>
                  </Row>
                </Card>
              )}
            </Space>
          </Col>
        </Row>
      </main>
    </SurveyContext>
  )
};

export default CoverageConfirmationPage;
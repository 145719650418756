import {Survey as SurveyReact} from "survey-react-ui";
import * as Survey from "survey-react";
import {Question, QuestionCheckboxModel, QuestionDropdownModel, QuestionRadiogroupModel} from "survey-react";
import {useParams} from "react-router-dom";
import {
  useGetNegotiationCoverageConfirmationQuery,
  useGetNegotiationDetailQuery,
  usePatchCoverageConfirmationMutation
} from "../../redux/api/negotiationsApiSlice";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {message, Skeleton} from "antd";
import {useCallback, useEffect} from "react";
import _, {debounce} from "lodash";
import {ChoicesRestfull, CustomWidgetCollection, Model} from "survey-core";
import "survey-core/defaultV2.min.css";
import {useSurveyContext} from "../../context/SurveyContext";
import {
  CustomCheckboxGroup,
  CustomDateInput,
  CustomNumericInput,
  CustomPercentageInput,
  CustomRadioGroup,
  CustomSelectInput
} from "../../components/negotiationForm/sidebars/status/modals/checklist";
import 'survey-core/modern.min.css';

const CoverageConfirmationSurvey = ({onCoverageConfirmationCompleted}: {

  onCoverageConfirmationCompleted?: (s: Survey.Survey) => void
}) => {

  const {id} = useParams();

  const {data: negotiation, isLoading: isNegotiationLoading} = useGetNegotiationDetailQuery(id ?? skipToken);

  const {
    data: coverageConfirmation,
    isLoading: isCoverageConfirmationLoading
  } = useGetNegotiationCoverageConfirmationQuery(negotiation?.coverageconfirmation ?? skipToken);

  const [
    patchCoverageConfirmation,
  ] = usePatchCoverageConfirmationMutation();

  const debounceUpdate = useCallback(debounce(({
                                                 uuid,
                                                 data
                                               }: {
    uuid: string,
    data: JSON
  }) => patchCoverageConfirmation({
    uuid,
    data
  }), 500), []);

  const debounceMessage = useCallback(debounce((success: boolean) => success ? message.success('dati aggiornati') : message.error('dati non aggiornati'), 500), [])


  const [survey, setSurvey] = useSurveyContext();

  useEffect(() => {
    // add custom widget
    CustomWidgetCollection.Instance.addCustomWidget({
      name: 'customnumeric',
      title: 'custom Numeric',
      render: (question: Question) => <CustomNumericInput question={question}/>,
      isFit: (question: Question) => question.name.charAt(0) === '$',
    }, "customnumeric")
    CustomWidgetCollection.Instance.addCustomWidget({
      name: 'custompercentage',
      title: 'custom Percentage',
      render: (question: Question) => <CustomPercentageInput question={question}/>,
      isFit: (question: Question) => question.name.charAt(0) === '%',
    }, "custompercentage")

    CustomWidgetCollection.Instance.addCustomWidget({
      name: 'customdropdown',
      title: 'custom Dropdown',
      render: (question: Question) => <CustomSelectInput question={question as QuestionDropdownModel}/>,
      isFit: (question: Question) => question.getType() === 'dropdown',
    }, "customdropdown")


    CustomWidgetCollection.Instance.addCustomWidget({
      name: 'customDate',
      title: 'custom date',
      render: (question: Question) => <CustomDateInput question={question as QuestionDropdownModel}/>,
      // @ts-ignore
      isFit: (question: Question) => question.inputType === 'date',
    }, "customDate")

    CustomWidgetCollection.Instance.addCustomWidget({
      name: 'customRadioGroup',
      title: 'custom radio group',
      render: (question: Question) => <CustomRadioGroup question={question as QuestionRadiogroupModel}/>,
      // @ts-ignore
      isFit: (question: Question) => question.getType() === 'radiogroup',
    }, "customRadioGroup")


    CustomWidgetCollection.Instance.addCustomWidget({
      name: 'customCheckboxGroup',
      title: 'custom checkbox group',
      render: (question: Question) => <CustomCheckboxGroup question={question as QuestionCheckboxModel}/>,
      // @ts-ignore
      isFit: (question: Question) => question.getType() === 'checkbox',
    }, "customCheckboxGroup")

    ChoicesRestfull.onBeforeSendRequest = function (sender, options: { request: XMLHttpRequest }) {
      const authToken = localStorage.getItem("accessToken");
      options.request.setRequestHeader("Authorization", "JWT " + authToken);
    }


  }, []);

  useEffect(() => {
    if (survey && coverageConfirmation?.data) {
      survey.data = coverageConfirmation.data;
    }
    if (!_.isEmpty(coverageConfirmation) && !survey ) {
      let surveySchema = _.cloneDeep(coverageConfirmation.schema);

      surveySchema
        // @ts-ignore
        .pages
        .forEach((page: any) =>
          page
            .elements
            .forEach((element: any) => {
              if (element.type === 'dropdown' && element.choicesByUrl?.url && !element.choicesByUrl.url.includes("http")) {
                element.choicesByUrl.url = `${process.env.REACT_APP_API_URL}${element.choicesByUrl.url}`
              }
              if (element.type === "matrixdynamic") {
                element.columns.forEach((el: any) => {
                  if (el.choicesByUrl?.url && !el.choicesByUrl.url.includes("http"))
                    el.choicesByUrl.url = `${process.env.REACT_APP_API_URL}${el.choicesByUrl.url}`
                })
              }
            }))

      const s = new Model(surveySchema);
      s.onValueChanged.add(async (sender: Survey.Model, question: Survey.Question) => {
        let results = s.data
        if (!_.isEmpty(results)) {
          s.pages.forEach((page: Survey.PageModel) =>
            page.elements.forEach((el: Survey.Question) => {
              if (!(el.name in s.data)) {
                results[el.name] = null
              }
            }));

          console.log('results: ', results);
          console.log('coverageConfirmation?.data: ', coverageConfirmation?.data)

          if (negotiation && negotiation?.coverageconfirmation !== null && results && !_.isEqual(coverageConfirmation?.data, results)) {
            try {
              await debounceUpdate({
                uuid: negotiation.coverageconfirmation,
                data: results
              })
              debounceMessage(true);
            } catch (e) {
              console.error(e);
            }
          }
        }
      });

      s.focusFirstQuestionAutomatic = false;
      s.setCss(
        {
          container: "ant-form ant-form-vertical",
          row: "ant-row ant-form-item",
          question: {
            header: "ant-col ant-form-item-label ant-form-item-required",
            title: "ant-form ant-form-item-label question-title",
            content: "ant-col ant-form-item-control",
            description: "ant-form-item-explain",
            hasError: "ant-form-item-explain-error",
          },
          text: {
            root: "ant-input",
          },
          radiogroup: {
            item: "ant-radio-wrapper ant-radio-wrapper-in-form-item",
            materialDecorator: "ant-radio",
          },
          comment: {
            root: "ant-input ant-input-textarea ant-input-textarea-in-form-item",
          },
          paneldynamic: {
            buttonAdd: 'ant-btn ant-btn-primary with-margin',
            buttonRemove: 'ant-btn with-margin',
          },
          matrixdynamic: {
            columns: "with-margin",
            button: 'ant-btn',
            buttonAdd: 'ant-btn ant-btn-primary with-margin',
          },
          navigation: {
            next: 'ant-btn ant-btn-primary visible custom-surveyjs-navigation-btn with-margin',
            prev: 'ant-btn visible custom-surveyjs-navigation-btn',
            complete: 'ant-btn ant-btn-primary visible custom-surveyjs-confirm-navigation-btn with-margin',
          }
        }
      )
      if (coverageConfirmation.data !== null) {
        s.data = coverageConfirmation.data;
      }

      setSurvey(s);
    }
  }, [coverageConfirmation, debounceMessage, debounceUpdate, negotiation, setSurvey, survey]);


  return (
    <>
      <Skeleton loading={(isNegotiationLoading || isCoverageConfirmationLoading) && !!survey} active={true}>
        {survey && <SurveyReact model={survey}/>}
      </Skeleton>
    </>
  );
};

export default CoverageConfirmationSurvey;
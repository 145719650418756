import {Navigate, Outlet, Route, Routes,} from "react-router-dom";

import Negotiations from "pages/negotiations";
import NewNegotiation from "pages/negotiations/new";
import NegotiationDetail from "pages/negotiations/negotiationId";
import Login from "components/login";
import Authorities from "pages/authorities";

import {useSelector} from "react-redux";
import {selectCurrentToken} from "redux/features/authSlice";
import Container from 'components/layout'
import React from "react";
import DashBoard from "./pages/dashboard";
import { selectUser } from "./redux/features/userSlice";
import { ContainerProps } from "./types/negotiations/components";
import TransitionFromRiskApp from "./components/TransitionFromRiskApp";
import Settings from "./pages/settings";
import {
    canSeeAuthorities,
    canSeeBankBranchesCharts,
    canSeeBrokerCharts,
    canSeeDocuments,
    canSeeGeneralDocuments,
    canSeePortfolioCharts,
    canSeeSettings,
    canSeeUWCharts
} from "./utils/permission";
import BrokersSettings from "./pages/settings/brokers";
import ReinsurersSettings from "./pages/settings/reinsurers";
import ReinsurerBrokersSettings from "./pages/settings/reinsurerBrokers";
import DelegatedInsurersSettings from "./pages/settings/delegatedInsurers";
import ContractorsSettings from "./pages/settings/contractors";
import Documents from "./pages/documents";
import GeneralDocuments from "./pages/generalDocuments";
import BrokerIndex from "./pages/commercialDetail/BrokerIndex";
import BranchIndex from "./pages/commercialDetail/BranchIndex";
import BrokerDetail from "./pages/commercialDetail/BrokerDetail";
import BranchDetail from "./pages/commercialDetail/BranchDetail";
import Branches from "./pages/settings/branches";
import BrokerChartsParamsContext from "./context/BrokerChartsContext";
import BankBranchesChartsParamsContext from "./context/BankBranchesChartsContext";
import BrokersChartsParamsContext from "./context/BrokersChartsContext";
import BankBranchChartsParamsContext from "./context/BankBranchChartsContext";
import CoverageConfirmation from "./pages/negotiations/CoverageConfirmation";


//https://reactrouter.com/docs/en/v6/getting-started/overview


export default function Router() {

    return (
        <Routes>
            {/* pages that contain /auth/ string don't set redux, check authMiddleWare for further information */}
            <Route path="auth/login" element={<Login />}/>
            <Route path="auth/:token" element={<TransitionFromRiskApp />}/>
            {/*<Route path="sessionexpired" element={<TransitionToRiskApp />}/>*/}

            <Route element={<HeaderContainer />}>
                <Route path="/" element={<Navigate to="negotiations" replace />} />
                <Route path="negotiations" element={<Negotiations />} />
                <Route path="negotiations/new" element={<NewNegotiation />} />
                <Route path="negotiations/:id" element={<NegotiationDetail />} />
                <Route path="negotiations/:id/coverageconfirmation" element={<CoverageConfirmation/>} />
                <Route path="documents/:id" element={<DocumentPermission><Documents /></DocumentPermission>} />
                <Route path="authorities" element={<AuthoritiesPermission><Authorities/></AuthoritiesPermission>}/>
                <Route path="generaldocuments"
                       element={<GeneralDocumentPermission><GeneralDocuments/></GeneralDocumentPermission>}/>
                <Route path="dashboard" element={<DashBoardPermission><DashBoard/></DashBoardPermission>}/>
                <Route path="brokers" element={
                    <BrokerChartsPermission>
                        <BrokersChartsParamsContext>
                            <BrokerIndex/>
                        </BrokersChartsParamsContext>
                    </BrokerChartsPermission>}
                />
                <Route path="brokers/:id" element={
                    <BrokerChartsPermission>
                        <BrokerChartsParamsContext>
                            <BrokerDetail/>
                        </BrokerChartsParamsContext>
                    </BrokerChartsPermission>}/>
                <Route path="branches" element={
                    <BankBranchesPermission>
                        <BankBranchesChartsParamsContext>
                            <BranchIndex/>
                        </BankBranchesChartsParamsContext>
                    </BankBranchesPermission>}
                />
                <Route path="branches/:id" element={
                    <BankBranchesPermission>
                        <BankBranchChartsParamsContext>
                            <BranchDetail/>
                        </BankBranchChartsParamsContext>
                    </BankBranchesPermission>
                }/>
                <Route element={<SettingsPermission/>}>
                    <Route path="settings" element={<Settings/>}/>
                    <Route path="settings/brokers" element={<BrokersSettings/>}/>
                    <Route path="settings/reinsurers" element={<ReinsurersSettings/>}/>
                    <Route path="settings/reinsurerbrokers" element={<ReinsurerBrokersSettings/>}/>
                    <Route path="settings/delegatedinsurers" element={<DelegatedInsurersSettings/>}/>
                    <Route path="settings/contractors" element={<ContractorsSettings/>}/>
                    <Route path="settings/branches" element={<Branches/>}/>
                </Route>
            </Route>
            <Route path="*" element={<Navigate to="/" replace/>}/>
        </Routes>
    );

}

function HeaderContainer() {
    const token = useSelector(selectCurrentToken)

    return (
        token ? <Container><Outlet/></Container> : <></>
    )
}


function AuthoritiesPermission({children}: ContainerProps) {
    const user = useSelector(selectUser)
    const isAuthorized: boolean = user && user.usertypes && canSeeAuthorities(user.usertypes.map(el => el.code))

    if (!isAuthorized) {
        return <Navigate to={'/'} replace/>;
    }
    return <>{children}</>;
}

function DashBoardPermission({children}: ContainerProps) {

    const user = useSelector(selectUser)
    const isAuthorized: boolean = user && user.usertypes && (canSeePortfolioCharts(user.usertypes.map(el => el.code)) || canSeeUWCharts(user.usertypes.map(el => el.code)))
    if (!isAuthorized) {
        return <Navigate to={'/'} replace/>;
    }
    return <>{children}</>;
}

function BrokerChartsPermission({children}: ContainerProps) {

    const user = useSelector(selectUser)
    const isAuthorized: boolean = user && user.usertypes && (canSeeBrokerCharts(user.usertypes.map(el => el.code)))
    if (!isAuthorized) {
        return <Navigate to={'/'} replace/>;
    }
    return <>{children}</>;
}

function BankBranchesPermission({children}: ContainerProps) {

    const user = useSelector(selectUser)
    const isAuthorized: boolean = user && user.usertypes && (canSeeBankBranchesCharts(user.usertypes.map(el => el.code)))
    if (!isAuthorized) {
        return <Navigate to={'/'} replace/>;
    }
    return <>{children}</>;
}

function SettingsPermission({children}: ContainerProps) {
    const user = useSelector(selectUser)
    const isAuthorized: boolean = user && user.usertypes && (canSeeSettings(user.usertypes.map(el => el.code)))
    if (!isAuthorized) {
        return <Navigate to={'/negotiations'} replace/>;
    }
    return <Outlet/>;
}

function DocumentPermission({children}: ContainerProps) {
    const user = useSelector(selectUser)
    const isAuthorized: boolean = user && user.usertypes && canSeeDocuments(user.usertypes.map(el => el.code))
    if (!isAuthorized) {
        return <Navigate to={'/negotiations'} replace/>
    }
    return <>{children}</>
}

function GeneralDocumentPermission({children}: ContainerProps) {
    const user = useSelector(selectUser)
    const isAuthorized: boolean = user && user.usertypes && canSeeGeneralDocuments(user.usertypes.map(el => el.code))
    if (!isAuthorized) {
        return <Navigate to={'/negotiations'} replace/>
    }
    return <>{children}</>
}




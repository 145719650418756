import {Alert, Checkbox, DatePicker, message, Modal, Radio, Select, Skeleton} from "antd";
import {NegotiationModel, UpdateNegotiationRequest} from "types/negotiations";

import {Survey as SurveyReact} from "survey-react-ui";
import * as Survey from "survey-react";
import {Question, QuestionCheckboxModel, QuestionDropdownModel, QuestionRadiogroupModel} from "survey-react";

import NumberFormat from "react-number-format";
import {useCallback, useEffect, useState} from "react";
import {
  useLazyCreateChecklistQuery,
  useLazyGetChecklistQuery,
  useUpdateChecklistMutation
} from "redux/api/checklistApiSlice";
import {debounce} from "lodash";
import moment from "moment";

export function CustomNumericInput({question}: { question: Question }) {
  return (
    <NumberFormat
      className={`ant-input ant-item-form ${question.isReadOnly ? 'ant-input-disabled' : ''}`}
      //style={{ minWidth: 220 }}
      value={question.value}
      displayType={"input"}
      decimalSeparator={","}
      thousandSeparator={"."}
      placeholder={"0.00 €"}
      suffix={" €"}
      fixedDecimalScale={true}
      readOnly={question.isReadOnly}
      decimalScale={2}
      onValueChange={(values) => {
        question.value = values.floatValue
      }}
    />
  )
}

export function CustomPercentageInput({question}: { question: Question }) {
  return (
    <NumberFormat
      className={`ant-input ant-item-form ${question.isReadOnly ? 'ant-input-disabled' : ''}`}
      //style={{ minWidth: 220 }}
      value={question.value}
      displayType={"input"}
      decimalSeparator={","}
      thousandSeparator={"."}
      placeholder={"0 %"}
      suffix={" %"}
      readOnly={question.isReadOnly}
      onValueChange={(values) => {
        question.value = values.floatValue
      }}
    />
  )
}

export function CustomDateInput({question}: { question: Question }) {
  return (
    <DatePicker
      value={question.value ? moment(new Date(question.value)) : null}
      format={"DD/MM/YYYY"}
      style={{width: '100%'/*, minWidth: 220*/ }}
      onChange={(value) => {
        if (value)
          question.value = value.format('YYYY-MM-DD');
        else question.value = null;
      }}/>
  )
}

export function CustomRadioGroup({question}: { question: QuestionRadiogroupModel }) {

  return (
    <>
      {question?.propertyHash && (
        <Radio.Group
          style={{ /*minWidth: 220,*/ overflow: "hidden" }}
          value={question.value}
          onChange={(e) => {
            question.value = e.target.value;
          }}>
          {question.propertyHash.choices.map((option: any, index: number) =>
            <Radio
              key={index}
              value={option.value}>
              {option.locTextValue.values.default}
            </Radio>
          )}
        </Radio.Group>
      )}
    </>
  );
}


export function CustomCheckboxGroup({question}: { question: QuestionCheckboxModel }) {

  return (
    <>
      {question?.choices && (
        <Checkbox.Group
          //style={{ minWidth: 220 }}
          value={question.value}
          options={question.choices.map((option: any) => ({label: option.text, value: option.value}))}
          onChange={(e) => {
            question.value = e;
          }}
        />
      )}
    </>
  );
}

export function CustomSelectInput({question}: { question: QuestionDropdownModel }) {

  return (
    <>
      {question?.choicesFromUrl && (
        <Select
          style={{width: '100%'/*, minWidth: 220*/ }}
          virtual={false}
          value={question.value}
          showSearch={true}
          filterOption={(input, option) =>
            ('' + (option?.label ?? '')).toLowerCase().includes(input.toLowerCase())
          }
          //value={question.value}
          onChange={(value) => {
            question.value = value
          }}
          options={question?.choicesFromUrl?.map((options: any) => ({
            label: options.locTextValue.calculatedTextValue,
            value: options.value
          }))}/>)}
      {!question?.choicesFromUrl && question.choices && (
        <Select
          style={{width: '100%'/*, minWidth: 220*/}}
          virtual={false}
          value={question.value}
          showSearch={true}
          dropdownMatchSelectWidth={false}
          filterOption={(input, option) =>
            ('' + (option?.label ?? '')).toLowerCase().includes(input.toLowerCase())
          }
          //value={question.value}
          onChange={(value) => {
            question.value = value
          }}
          options={question?.choices?.map((options: any) => ({
            label: options.text,
            value: options.value
          }))}/>
      )}
    </>
  )
}


export function ChecklistFormDialog({
                                      isOpen,
                                      onClose,
                                      negotiation,
                                      updateNegotiation,
                                    }: {
  isOpen: boolean,
  onClose: (isNegotiationToUpdate: boolean) => void,
  negotiation: NegotiationModel,
  updateNegotiation: (data: Partial<UpdateNegotiationRequest>) => void
}) {

  const [survey, setSurvey] = useState(new Survey.Model())
  const [updateChecklist] = useUpdateChecklistMutation()
  const debounceUpdate = useCallback(debounce(({
                                                 negotiation,
                                                 checklist,
                                                 data,
                                                 visible_questions,
                                               }: {
    negotiation: string,
    checklist: string,
    data: object,
    visible_questions: { [key: string]: boolean }
  }) => updateChecklist({
    negotiation,
    checklist,
    data,
    visible_questions
  }), 500), [])
  const debounceMessage = useCallback(debounce((success: boolean) => success ? message.success('Checklist aggiornata') : message.error('Checklist non aggiornata'), 500), [])
  const [getChecklist, {
    data: getChecklistData,
    isLoading: getChecklistIsLoading,
    isFetching: getChecklistIsFetching
  }] = useLazyGetChecklistQuery()

  const [createChecklist, {
    data: createChecklistData,
    isLoading: createChecklistIsLoading,
    isFetching: createChecklistIsFetching,
  }] = useLazyCreateChecklistQuery()

  const [isChecklistCreated, setIsChecklistCreated] = useState(false)

  useEffect(() => {
    // getData
    if (isOpen) {
      const assuChecklistExist = negotiation.checklist_answers.find(c => c.checklist_type === 'ASSU')
      if (assuChecklistExist) {
        getChecklist({negotiation: negotiation.uuid, checklist: assuChecklistExist.uuid})
      } else {
        createChecklist({negotiation: negotiation.uuid, checklist_type: 'ASSU'})
          .unwrap()
          .then(res => {
            setIsChecklistCreated(true)
          })
          .catch()
      }
    }
  }, [createChecklist, getChecklist, isOpen, negotiation.checklist_answers, negotiation.uuid, updateNegotiation])

  useEffect(() => {
    // set jsonSchema
    const surveyData = getChecklistData || createChecklistData;

    if (surveyData?.jsonSchema && surveyData?.data) {
      const newSurvey = new Survey.Model(surveyData?.jsonSchema)
      newSurvey.data = surveyData.data
      if (isChecklistCreated) {
        // when the checklist is created defaultValues are passed
        const questions = newSurvey.getAllQuestions().reduce((prev, q) => ({
          ...prev,
          [q.name]: q.isVisible
        }), {})
        updateChecklist({
          negotiation: negotiation.uuid,
          checklist: surveyData.uuid,
          data: newSurvey.data,
          visible_questions: questions
        })
      }

      newSurvey.onValueChanged.add((sender: Survey.Model, question: Survey.Question) => {
        let results = newSurvey.data
        const questions = sender.getAllQuestions().reduce((prev, q) => ({...prev, [q.name]: q.isVisible}), {})
        surveyData?.jsonSchema.pages[0].elements.forEach((el: Survey.Question) => {
          if (!(el.name in newSurvey.data)) {
            results[el.name] = null
          }
        })
        debounceUpdate({
          negotiation: negotiation.uuid,
          checklist: surveyData.uuid,
          data: results,
          visible_questions: questions
        })
          ?.unwrap()
          .then(() => debounceMessage(true))
          .catch(() => debounceMessage(false))

      });
      newSurvey.focusFirstQuestionAutomatic = false;
      newSurvey.setCss(
        {
          container: "ant-form ant-form-vertical",
          row: "ant-row ant-form-item",
          question: {
            header: "ant-col ant-form-item-label ant-form-item-required",
            title: "ant-form ant-form-item-label question-title",
            content: "ant-col ant-form-item-control",
            description: "ant-form-item-explain",
            hasError: "ant-form-item-explain-error",
          },
          text: {
            root: "ant-input",
          },
          radiogroup: {
            item: "ant-radio-wrapper ant-radio-wrapper-in-form-item",
            materialDecorator: "ant-radio",
          },
          comment: {
            root: "ant-input ant-input-textarea ant-input-textarea-in-form-item",
          },
        }
      )
      setSurvey(newSurvey)
    }
  }, [createChecklistData, debounceMessage, debounceUpdate, getChecklistData, isChecklistCreated, negotiation.uuid, updateChecklist])

  useEffect(() => {
    // add custom widget
    Survey.CustomWidgetCollection.Instance.addCustomWidget({
      name: 'customnumeric',
      title: 'custom Numeric',
      render: (question: Question) => {
        return <CustomNumericInput question={question}/>
      },
      isFit: (question: Question) => {
        return question.name.charAt(0) === '$';
      },
    }, "customnumeric")
    Survey.CustomWidgetCollection.Instance.addCustomWidget({
      name: 'custompercentage',
      title: 'custom Percentage',
      render: (question: Question) => {
        return <CustomPercentageInput question={question}/>
      },
      isFit: (question: Question) => {
        return question.name.charAt(0) === '%';
      },
    }, "custompercentage")
  }, [])

  return (
    <div className='checklist-modal'>
      <Modal
        title="Checklist"
        centered
        open={isOpen}
        onOk={() => {
          onClose(isChecklistCreated)
          setIsChecklistCreated(false)
        }}
        onCancel={() => {
          onClose(isChecklistCreated)
          setIsChecklistCreated(false)
        }}
        footer={null}
        destroyOnClose={true}
      >
        <Alert
          style={{marginBottom: '1rem'}}
          message={"I campi disabilitati recuperano automaticamente i dati dal form della trattativa. La modifica di quei campi deve avvenire nel form."}
          type={"info"} showIcon/>
        {!(getChecklistIsLoading || getChecklistIsFetching || createChecklistIsLoading || createChecklistIsFetching) &&
          <SurveyReact model={survey}/>}
        {(getChecklistIsLoading || getChecklistIsFetching || createChecklistIsLoading || createChecklistIsFetching) &&
          <Skeleton style={{height: '100vh'}} paragraph={{rows: 40}}/>}
      </Modal>
    </div>
  )
}
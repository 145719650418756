import {Button, Col, Divider, Form, message, Modal, Row, Typography} from "antd";
import React, {useCallback, useState} from "react";
import Dragger from "antd/es/upload/Dragger";
import {UploadDoc} from "../../components/documents/UploadDocumentModal";
import {CloseCircleOutlined} from "@ant-design/icons";
import DocListItem from "../../components/documents/DocListItem";
import {useLazyRequestToSaveDocumentsQuery, useUpdateDocumentMutation} from "../../redux/api/documentsApiSlice";
import axios from "axios";
import {useParams} from "react-router-dom";
import {useGetNegotiationDetailQuery, useStartPatchFromRoreMutation} from "../../redux/api/negotiationsApiSlice";
import {skipToken} from "@reduxjs/toolkit/query";

const {Text} = Typography;
const MAX_FILE_SIZE = 50 * 1024 * 1024 // 50 MB

const CoverageConfirmationDocumentUploadModalButton = () => {

  const {id: negotiationId} = useParams();

  const [form] = Form.useForm();

  const [saveDocument] = useLazyRequestToSaveDocumentsQuery();
  const [updateDocument] = useUpdateDocumentMutation();
  const [startPatchFromRore] = useStartPatchFromRoreMutation();
  const {
    data: negotiation,
  } = useGetNegotiationDetailQuery(negotiationId ?? skipToken)

  const [open, setOpen] = useState(false);
  const [isAddingDocument, setIsAddingDocument] = useState(false);

  const [docList, setDocList] = useState<UploadDoc[]>([]);
  const [realFiles, setRealFiles] = useState<File[][]>([]);
  const [filesTooLong, setFilesTooLong] = useState<string[]>([]);

  const [loadingUpload, setLoadingUpload] = useState<boolean>(false);


  const saveInAws = useCallback((data: any, file: File) => {
    const postData = new FormData()
    for (const key in data.fields)
      postData.append(key, data.fields[key])
    postData.append('file', file)
    return axios.post(data.url, postData)
  }, [])


  const saveFiles = async (file: File, fileName: string | undefined) => {
    try {
      const responseSaveDocs = await saveDocument({
        path: "4.2",
        name: fileName,
        negotiation: negotiationId,
        content_type: file.type
      }).unwrap();
      await saveInAws(responseSaveDocs, file)
      const documentUploadResults = await updateDocument({
        uuid: responseSaveDocs.uuid,
        data: {is_valid: true, negotiation: negotiationId}
      }).unwrap();
      if (negotiation && negotiation?.coverageconfirmation !== null) {
        startPatchFromRore({
          coverageConfirmationUuid: negotiation.coverageconfirmation,
          documentUuid: documentUploadResults.uuid
        });
      }

    } catch {
      return new Error('errore nel caricamento o nel salvataggio dei file.')
    }
  }

  const handleUploadDocument = async (files: UploadDoc[], realFiles: File[][]) => {
    setLoadingUpload(true);
    Promise
      .all(realFiles
        .flat()
        .map((file, index) =>
          saveFiles(file, files[index].fileName)))
      .finally(() =>
        setLoadingUpload(false));
  }

  function handleUpload(files: any) {
    setRealFiles((realFiles) =>
      [
        ...realFiles,
        files
          .filter((el: any) => el.size <= MAX_FILE_SIZE)
          .map((el: any) => el.originFileObj)
      ]
    );
  }

  return (
    <>
      <Modal
        title={"Documenti"}
        open={open}
        okButtonProps={{
          disabled: docList.length === 0 || isAddingDocument || loadingUpload,
          loading: loadingUpload
        }}
        okText={"Carica"}
        onCancel={() => {
          form.resetFields();
          setDocList([]);
          setIsAddingDocument(false);
          setFilesTooLong([]);
          setOpen(false);
        }}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              handleUploadDocument(docList, realFiles).then(() => {
                message.success(docList?.length > 1 ? 'documenti caricati con successo' : 'documento caricato con successo');
                form.resetFields();
                setOpen(false);
              })
                .catch(() => message.error('errore nel caricamento dei documenti.'))

            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          name={"documentUpload"}
          layout={"vertical"}
        >
          <Form.Item
            style={{marginBottom: '0.5rem'}}
            name={"documents"}
            label={"I documenti caricati verranno salvati nella cartella \"Ordine fermo - Conferma di copertura\" e letti dal sistema per estrarre i dati"}>
            <Dragger
              onDrop={e => console.log('dropped: ', e)}
              style={{padding: '1rem 0'}}
              accept={"image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-word.document.macroEnabled.12, application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel.sheet.macroEnabled.12, message/rfc822, application/vnd.ms-outlook, application/x-msg, application/octet-stream, application/acad, application/vnd.oasis.opendocument.text, application/vnd.oasis.opendocument.spreadsheet, application/pkcs7-mime, application/x-pkcs7-mime"}
              multiple={true}
              disabled={isAddingDocument}
              fileList={docList}
              onChange={(info) => {
                handleUpload(info.fileList);
              }}
              onRemove={file => {
                setDocList(fileList => fileList.filter(uploadedDoc => uploadedDoc.uid !== file.uid));
              }}
              beforeUpload={(file, fileList) => {
                let _file = {
                  ...file as object,
                  name: file.name,
                  fileName: file.name,
                  dirType: '4.2'
                } as unknown as UploadDoc;
                form.resetFields()
                //setIsAddingDocument(true)
                const isBelowMaxSize = file.size <= MAX_FILE_SIZE;
                if (!isBelowMaxSize) {
                  setFilesTooLong(prevState => {
                    return [...prevState, file.name]
                  })
                } else
                  setDocList(prevState => [...prevState, _file])
                return false
              }}
              itemRender={(originNode, file, fileList, actions) => {
                const index = docList.findIndex(el => el.uid === file.uid);
                return <DocListItem selectOptions={undefined}
                                    file={file}
                                    index={index}
                                    disabledButton={isAddingDocument}
                                    setDisabledButton={setIsAddingDocument}
                                    fileList={docList}
                                    remove={actions.remove}
                                    dirType={undefined}
                                    setDocList={setDocList}/>
              }}
            >
              <Text>Trascina o seleziona un documento da caricare</Text>
              {filesTooLong.length !== 0 && <div>
                <Divider/>
                <Row justify={"center"} style={{marginBottom: "15px"}}>
                  <h3>
                    I seguenti documenti non verranno caricati
                  </h3>
                </Row>
                {filesTooLong.map(el => {
                  return <Row justify={"space-between"}>
                    <Col span={12}>
                      <div className={"ellipsis-name"}>{el}</div>
                    </Col>
                    <Col span={12}>
                      <div className={"ellipsis-name"}><CloseCircleOutlined style={{color: "red", marginRight: "5px"}}/>Dimensione
                        massima superata
                      </div>
                    </Col>
                  </Row>
                })}
              </div>}
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>
      <Button type={"primary"} onClick={() => setOpen(true)}>Carica documenti</Button>
    </>
  );
}

export default CoverageConfirmationDocumentUploadModalButton;
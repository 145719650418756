import {useGetNegotiationStatusTransitionsQuery} from "../../redux/api/negotiationsApiSlice";
import {Alert, Skeleton, Space} from "antd";
import {States} from "../../types";
import {isNegotiationExpired} from "../../utils/formatters";
import dayjs from "dayjs";
import {NegotiationModel} from "../../types/negotiations";

const NegotiationAlerts = ({negotiation}: { negotiation: NegotiationModel }) => {

  const {
    data: negotiationStatusHistory,
    isFetching: isFetchingNegotiationHistory,
    isLoading: isLoadingNegotiationHistory,
  } = useGetNegotiationStatusTransitionsQuery(negotiation.uuid);

  const lastState = negotiationStatusHistory?.at(-1);
  const previousState = negotiationStatusHistory?.at(-2);

  return (
    <Skeleton
      active={true}
      loading={isLoadingNegotiationHistory || isFetchingNegotiationHistory}>
      <Space
        direction={"vertical"}
        size={"small"}
        style={{display: 'flex'}}>
        {
          (
            negotiationStatusHistory &&
            negotiationStatusHistory.length > 2 && (
              lastState?.state_to === States.Working ||
              lastState?.state_to === States.ReferralApproved
            ) &&
            lastState?.state_from === States.Referred
          ) && (
            //display this alert when the state is Working or Referral approved both from Referred state
            <Alert
              message="Referral approved"
              description={lastState?.state_to === States.Working ? (
                previousState?.notes
              ) : (
                lastState?.notes
              )}
              style={{width: '100%'}}
              showIcon={true}
            />
          )
        }
        {
          (
            negotiationStatusHistory &&
            !!negotiationStatusHistory.length &&
            lastState?.state_to === States.Referred
          ) && (
            //display this alert when the state is Referred (always from Working)
            <Alert
              message="Referred"
              description={lastState?.notes}
              style={{width: '100%'}}
              showIcon={true}
            />
          )
        }
        {
          (
            negotiationStatusHistory &&
            !!negotiationStatusHistory.length &&
            lastState?.state_to === States.ReferralDeclined
          ) && (
            //display this alert when the state is Referred Declined
            <Alert
              type={'error'}
              message="Referred declined"
              description={lastState?.notes}
              style={{width: '100%'}}
              showIcon={true}
            />
          )
        }
        {
          isNegotiationExpired(negotiation.state, dayjs(negotiation.policy_effective_date)) && (
            <Alert
              message="Attenzione"
              description={'La data di decorrenza è passata'}
              type={'error'}
              style={{width: '100%'}}
              showIcon={true}
            />
          )
        }
      </Space>
    </Skeleton>
  );
}

export default NegotiationAlerts;
import {NegotiationModel, UpdateNegotiationRequest} from "../../../../types/negotiations";
import {Skeleton, Space, Typography} from "antd";
import {States} from "../../../../types";
import dayjs from "dayjs";
import React, {ReactNode} from "react";
import {useSelector} from "react-redux";
import {selectUser} from "../../../../redux/features/userSlice";
import {canModifyLob, canModifyUnderwriterInCharge} from "../../../../utils/permission";
import EditUnderwriterButton from "./modals/EditUnderwriterButton";
import EditLobButton from "./modals/EditLobButton";

const {Paragraph, Title, Text} = Typography;

const DetailListItem = ({
                          label,
                          value,
                          fieldNotAvailableMessage = 'Non presente',
                          action,
                          fieldFromRore,
                          fieldData
                        }: {
  label: string,
  value?: string | number | null,
  fieldNotAvailableMessage?: string,
  action?: ReactNode,
  fieldFromRore?: boolean,
  fieldData?: string
}) => {
  return (
    <Space direction={"vertical"} size={0}>
      <Space
        direction={'horizontal'}
        size={'middle'}
        align={'start'}>
        <Paragraph>
          {label}
        </Paragraph>
        <Title
          underline={!value}
          level={5}>
          {value || fieldNotAvailableMessage}
        </Title>
        {action && (
          action
        )}
      </Space>
      {fieldFromRore && (
        <Text type={"warning"}>
          {`Dati trovati da RORE: ${fieldData}`}
        </Text>
      )}
    </Space>
  );
}

const NegotiationDetailList = ({
                                 negotiation,
                                 isLoading,
                                 editUnderwriter,
                                 updateNegotiation
                               }: {
  negotiation: NegotiationModel | undefined,
  isLoading?: boolean,
  fieldNotAvailableMessage?: string,
  editUnderwriter?: (uwId: string) => Promise<NegotiationModel | undefined>,
  updateNegotiation?: (data: Partial<UpdateNegotiationRequest>) => Promise<NegotiationModel | undefined>,
}) => {

  const user = useSelector(selectUser);

  return (
    <>
      {!negotiation && isLoading && (
        <Skeleton active={true}/>
      )}
      {negotiation && (
        <Space direction={"vertical"} size={4}>
          {negotiation.is_imported &&
            <Paragraph>
              Trattativa importata da Excel
            </Paragraph>
          }
          <DetailListItem
            label={'Contraente'}
            value={negotiation?.contractor?.name}
          />
          <DetailListItem
            label={'LOB'}
            value={negotiation?.lob.name}
            action={
              <>
                {
                  user &&
                  updateNegotiation &&
                  canModifyLob(user?.usertypes?.map(el => el.code)) &&
                  negotiation.state === States.Rore && (
                    <EditLobButton
                      negotiation={negotiation}
                      updateNegotiation={updateNegotiation}/>
                  )
                }
              </>
            }
          />
          <DetailListItem
            label={"Underwriter"}
            value={negotiation?.underwriter?.user_full_name}
            action={
              <>
                {
                  user &&
                  canModifyUnderwriterInCharge(user?.usertypes?.map(el => el.code)) &&
                  editUnderwriter &&
                  negotiation.state >= States.Assegnata && (
                    <EditUnderwriterButton
                      negotiation={negotiation}
                      updateUnderwriter={editUnderwriter}/>
                  )}
              </>
            }
            fieldFromRore={negotiation.state === States.Rore && !!negotiation.raw_data?.underwriter?.length}
            fieldData={negotiation?.raw_data?.underwriter.join(', ')}
          />

          <DetailListItem
            label={'Data di ricezione'}
            value={negotiation.reception_date ? dayjs(negotiation.reception_date).format('DD/MM/YYYY') : null}
          />
          <DetailListItem
            label={'Data di decorrenza'}
            value={negotiation.policy_effective_date ? dayjs(negotiation.policy_effective_date).format('DD/MM/YYYY') : null}
          />
          <DetailListItem
            label={'Data di scadenza'}
            value={negotiation.policy_expiring_date ? dayjs(negotiation.policy_expiring_date).format('DD/MM/YYYY') : null}
          />
          <DetailListItem
            label={'UW Year'}
            value={negotiation?.uw_year}
          />
          <DetailListItem
            label={'Broker'}
            value={negotiation?.broker?.name}/>
          <DetailListItem
            label={'ISIA'}
            value={!!negotiation?.is_isia ? 'SI' : 'NO'}/>
          {!!negotiation?.is_isia && (
            <DetailListItem
              label={'Sale ISIA'}
              value={negotiation?.isia_sale?.user_full_name}/>
          )}
          {!negotiation.is_isia && !!negotiation?.isia_sale &&
            <DetailListItem
              label={'Sale'}
              value={negotiation?.isia_sale?.user_full_name}/>
          }


        </Space>
      )}
    </>
  );
}

export default NegotiationDetailList;
import {Card, Space, Steps, Typography} from "antd";
import {useSurveyContext} from "context/SurveyContext";
import {useState} from "react";

const {Title} = Typography;
const {Step} = Steps;
const SelectSurveyPageCard = ({disabled}:{disabled: boolean}) => {

  const [survey,] = useSurveyContext();
  const [selectedPage, setSelectedPage] = useState<number | undefined>(undefined);

  const handleChange = (event: number) => {
    if (survey && !disabled) {
      survey.currentPageNo = event;
      setSelectedPage(event);
    }

  };

  return (
    <>
      <Card>
        <Space direction={"vertical"} size={8}>
          <Title level={5}>
            Indice pagine
          </Title>
          <Steps
            direction={"vertical"}
            size={"small"}
            onChange={handleChange}
            current={selectedPage}>
            {survey?.pages.map((page: any, index: number) => (
              <Step title={`${index + 1} - ${page.title}`} key={index}/>
            ))}
          </Steps>
        </Space>
      </Card>
    </>
  );
}
export default SelectSurveyPageCard;
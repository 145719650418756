import {createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState} from 'react';
import {Model} from "survey-core";

export type SurveyContextType = [
  survey: Model | undefined,
  setSurvey: Dispatch<SetStateAction<Model | undefined>>
]

const surveyContext = createContext<SurveyContextType>([
  undefined,
  () => null,
]);

export const useSurveyContext = () => useContext(surveyContext);

export const SurveyContext = (props: PropsWithChildren) => {
  const [survey, setSurvey] = useState<Model | undefined>();

  return (
    <surveyContext.Provider value={[survey, setSurvey]}>
      {props.children}
    </surveyContext.Provider>
  );
};
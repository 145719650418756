import {BaseQueryFn, createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {RootState} from 'redux/store'
import {FetchArgs, FetchBaseQueryError} from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import {logOut} from "../features/authSlice";
import {Modal} from "antd";
import _ from "lodash";

const serverError = _.debounce((title: string, content: string) => Modal.error({
  title,
  content,
}), 100)

const authError = _.debounce((title: string, content: string) => Modal.error({
  title,
  content,
}), 100)


const baseQuery = fetchBaseQuery({
  // baseUrl: 'https://95.216.170.121/midori/v02',
  baseUrl: process.env.REACT_APP_API_URL || 'https://ayakodev.riskapp.it/midori/v02',
  // credentials: 'include',
  // mode: 'cors',
  prepareHeaders: (headers, {getState}) => {
    const token = (getState() as RootState).auth.token
    if (token)
      headers.set('authorization', `JWT ${token}`)
    // headers.set('Access-Control-Allow-Credentials', 'true')
    return headers
  }

})

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (api, args, extraOptions) => {
  let result = await baseQuery(api, args, extraOptions)
  if (result?.error?.status === 401) {
    // const refreshResult = await baseQuery('/refresh', api, extraOptions)
    // if (refreshResult?.data) {
    //     const user = api.getState().auth.user
    //     //store new token
    //     api.dispatch(setCredentials({ ...refreshResult.data, user }))
    //     //retry oginal query with new accesstoken
    //     result = await baseQuery(args, api, extraOptions)
    // }
    // else {
    //     api.dispatch(logOut())
    // }
    const modal = Modal.warning({
      title: "Sessione scaduta",
      content: 'Verrai reindirizzato alla pagina di login...',
      onOk: () => {
        modal.destroy();
        args.dispatch(logOut())
      }
    })
    setTimeout(() => {
      modal.destroy();
      args.dispatch(logOut())
    }, 2000);

  }
  if (result?.error?.status === "PARSING_ERROR" || result?.error?.status === "FETCH_ERROR" || result?.error?.status === 500 || result?.error?.status === 0) {
    serverError('Errore nel caricamento dei dati', 'Se il problema persiste contattaci all\' indirizzo support@riskapp.it',)
  }

  if (result?.error?.status === 403) {
    authError('Autorizzazione negata', 'Non hai il permesso per effettuare questa operazione')
  }

  return result
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({}),
    tagTypes: [
        'Agreements',
        'BankBranches',
        'Brokers',
        'MeetingNotes',
        'StateTransitions',
        'CoverageConfirmation',
    ],
})
import {UserTypes} from "../types";

export const canSeeReferralButton = (users: UserTypes[]) => users.some(el=> [UserTypes.UW, UserTypes.UWJ, UserTypes.UWS, UserTypes.LBM, UserTypes.CM, UserTypes.ADMIN].includes(el))

export const canSeeUWCharts = (users: UserTypes[]) => users.some(el=> [UserTypes.UW, UserTypes.UWJ, UserTypes.UWS, UserTypes.ADMIN, UserTypes.CM, UserTypes.CMO, UserTypes.LBM, UserTypes.CO, UserTypes.RO, UserTypes.COMM].includes(el))
export const canSeePortfolioCharts = (users: UserTypes[]) => users.some(el=> [UserTypes.PO].includes(el))

export const canSeeBrokerCharts =  (users: UserTypes[]) => users.some(el=> [UserTypes.ADMIN, UserTypes.COMM, UserTypes.SA, UserTypes.CM, UserTypes.LBM].includes(el))
export const canSeeBankBranchesCharts =  (users: UserTypes[]) => users.some(el=> [UserTypes.ADMIN, UserTypes.COMM, UserTypes.SA, UserTypes.CM, UserTypes.LBM].includes(el))

export const canSeeOtherTablesViews = (users: UserTypes[]) => users.some(el=> [UserTypes.UW, UserTypes.UWJ, UserTypes.UWS, UserTypes.ADMIN, UserTypes.CM, UserTypes.LBM].includes(el))

export const canCreateNewNegotiation = (users: UserTypes[]) => users.some(el=> [UserTypes.UW, UserTypes.UWJ, UserTypes.UWS, UserTypes.ADMIN, UserTypes.CM, UserTypes.LBM].includes(el))
export const canDeleteNegotiation = (users: UserTypes[]) => users.some(el=> [UserTypes.UW, UserTypes.UWJ, UserTypes.UWS, UserTypes.ADMIN, UserTypes.CM, UserTypes.LBM].includes(el))
export const canExtendNegotiation = (users: UserTypes[]) => users.some(el=> [UserTypes.UW, UserTypes.UWJ, UserTypes.UWS, UserTypes.ADMIN, UserTypes.CM, UserTypes.LBM].includes(el))

export const canModifyForm = (users: UserTypes[]) => users.some(el=> [UserTypes.UW, UserTypes.UWJ, UserTypes.UWS, UserTypes.ADMIN, UserTypes.CM, UserTypes.LBM, UserTypes.PO].includes(el))
export const canAlignCustomerContractor = (users: UserTypes[]) => users.some(el=> [UserTypes.UW, UserTypes.UWJ, UserTypes.UWS, UserTypes.ADMIN, UserTypes.CM, UserTypes.LBM, UserTypes.PO, UserTypes.SA, UserTypes.COMM].includes(el))

export const canSeeCheckList = (users: UserTypes[]) => users.some(el=> [UserTypes.UW, UserTypes.UWJ, UserTypes.UWS, UserTypes.ADMIN, UserTypes.CM, UserTypes.LBM].includes(el))

export const canSeeAuthorities = (users: UserTypes[]) => users.some(el=> [UserTypes.ADMIN, UserTypes.CM, UserTypes.LBM, UserTypes.UW, UserTypes.UWJ, UserTypes.UWS ].includes(el))
export const canModifyGapToPlan = (users: UserTypes[]) => users.some(el=> [UserTypes.ADMIN, UserTypes.CM, UserTypes.LBM].includes(el))

export const canSeeDocuments = (users: UserTypes[]) => users.some(el=> [UserTypes.UW, UserTypes.UWJ, UserTypes.UWS, UserTypes.ADMIN, UserTypes.CM, UserTypes.LBM, UserTypes.PO, UserTypes.RO, UserTypes.PM, UserTypes.CO, UserTypes.CMO, UserTypes.SA, UserTypes.COMM].includes(el))
export const canSeeGeneralDocuments = (users: UserTypes[]) => users.some(el=> [UserTypes.UW, UserTypes.UWJ, UserTypes.UWS, UserTypes.ADMIN, UserTypes.CM, UserTypes.LBM, UserTypes.PO, UserTypes.PM].includes(el))

export const canModifyUnderwriterInCharge = (users: UserTypes[]) => users.some(el=> [UserTypes.UWJ, UserTypes.UWS, UserTypes.UW, UserTypes.LBM, UserTypes.CM, UserTypes.ADMIN].includes(el))
export const canModifyLob = (users: UserTypes[]) => users.some(el=> [UserTypes.UWJ, UserTypes.UWS, UserTypes.UW, UserTypes.LBM, UserTypes.CM, UserTypes.ADMIN].includes(el))

export const canModifyPortfolioInCharge = (users: UserTypes[]) => users.some(el=> [UserTypes.PM].includes(el))

export const canSeeSettings = (users: UserTypes[]) => users.some(el=> [UserTypes.UW, UserTypes.UWJ, UserTypes.UWS, UserTypes.ADMIN, UserTypes.CM, UserTypes.LBM, UserTypes.SA, UserTypes.COMM].includes(el))

export const canGoToSubmission = (users: UserTypes[]) => users.some(el=> [UserTypes.UW, UserTypes.UWJ, UserTypes.UWS, UserTypes.ADMIN, UserTypes.CM, UserTypes.LBM, UserTypes.SA, UserTypes.COMM].includes(el))
export const canDownloadBrokerDataXlsx = (users: UserTypes[]) => users.some(el=> [UserTypes.ADMIN, UserTypes.LBM, UserTypes.CM, UserTypes.CO, UserTypes.SA, UserTypes.COMM].includes(el))

export const canUseAdvancedSearchGeneralTable = (users: UserTypes[]) => users.some(el=> [UserTypes.ADMIN, UserTypes.LBM, UserTypes.CM].includes(el))

export const canModifyCommercialDetail = (users: UserTypes[]) => users.some(el=> [UserTypes.ADMIN, UserTypes.COMM, UserTypes.SA].includes(el))

export const canDownloadFeeBank = (users: UserTypes[]) => users.some(el=> [UserTypes.ADMIN, UserTypes.COMM].includes(el))
import {Button, Form, Select} from "antd";
import Input from "antd/lib/input/Input";
import React, {useEffect} from "react";
import {useForm} from "antd/lib/form/Form";
import {useCachedCountriesQuery} from "../../redux/api/countriesApiSlice";

export const COUNTRY = 'country'
export const NAME = 'name'

export type FormType = { [COUNTRY]: string, [NAME]: string }

export default function AutoPilotForm({country, searchTerm, handleFinish, disabled, isFund = false}: {country?: string | null, searchTerm?: string, handleFinish: (values: FormType) => void, disabled: boolean, isFund? : boolean}) {

  const [autoPilotForm] = useForm()
  const {data: fetchedCountries, isLoading: isLoadingCountries} = useCachedCountriesQuery()
  const countries = fetchedCountries?.map(c => ({label: c.label, value: c.code})) || []

  useEffect(()=> {
    autoPilotForm.setFieldsValue({
      [COUNTRY]: country || 'IT',
      [NAME]: searchTerm || ""
    })
  }, [country, autoPilotForm, searchTerm])

  return <>
    <p style={{fontStyle: 'italic'}}>Inserire i dati per caricare una lista di candidati, premere il pulsante "Avvia ricerca" e infine selezionare il candidato.</p>
    <Form
      <FormType>
      form={autoPilotForm}
      layout={'vertical'}
      onFinish={handleFinish}
      style={{marginTop: '1rem'}}
  >
    <Form.Item name={COUNTRY} label={'Paese'} rules={[{required: true, message: "Campo obbligatorio"}]}>
      <Select
          optionFilterProp={'label'}
          showSearch
          options={countries}
          loading={isLoadingCountries}
          virtual={false}
      />
    </Form.Item>
    <Form.Item name={NAME} label={'Ragione sociale / P.IVA'} rules={[{required: true, message: "Campo obbligatorio"}, {min: 3, message: 'Minimo 3 caratteri'}]}>
      <Input placeholder={isFund ? "Nome cassa di assistenza" : "Nome contraente"}/>
    </Form.Item>
    <Form.Item shouldUpdate style={{textAlign: 'right'}}>
      {(formInstance) => <Button
          type={'primary'}
          htmlType={'submit'}
          disabled={disabled}
      >
        Avvia ricerca</Button>}
    </Form.Item>
  </Form>
    </>
}